html {
  height: 100%;
  overflow-y: auto;
}

body {
  height: 100%;
}

html,
body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.1;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: default;
}

.website {
  height: 100%;
  overflow-y: scroll;
}

.info {
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
  min-height: 50vh;
  position: relative;
}

.small-info {
  padding: 25px 40px;
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
}

.instruction-info {
  padding: 20px 40px;
  max-width: 800px;
  margin: 0 auto;
  height: 100%;
}

.intro {
  min-height: 100vh;
  max-height: 100vh;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  position: relative;
  background-image: linear-gradient(0deg, #f4f4f4, #fff);
}

.home-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.title {
  display: block;
  font-size: 6em;
  font-weight: 700;
  margin: 55px 0 28px;
  color: #e85454;
}

.description {
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  margin: 0 0 48px;
  color: #666;
}

.start-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
}

.start {
  display: inline-block;
  width: 110px;
}

.start-button {
  width: auto;
  background-color: transparent;
  color: #e85454;
  display: inline-block;
  clear: right;
  padding: 10px 45px;
  position: relative;
  top: 0;
  margin: 0 8px;
  border-radius: 32px;
  border: 2px solid #e85454;
  height: 32px;
  line-height: 32px;
  font-weight: 700;
  font-size: 0.9em;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  transition: all 0.1s ease-in-out;
}

.start-button:hover {
  background-color: #e85454;
  color: #fff;
}

.start-button:active {
  top: 2px;
}

.enter-help {
  display: inline-block;
  font-size: 0.9em;
  clear: both;
  width: 110px;
  color: #aaa;
}

.why {
  height: auto;
  background-color: #fff;
  padding: 16px 0 0;
}

.why-title {
  padding: 0 0 12px;
  font-size: 18px;
  font-weight: 700;
  color: #e85454;
  text-align: center;
}

.why-description {
  padding: 0 12px;
  color: #666;
  text-align: center;
}

.instructions {
  height: auto;
  background-color: #fff;
  padding: 0 0 16px;
}

.instructions-title {
  padding: 0 0 12px;
  font-size: 18px;
  font-weight: 700;
  color: #e85454;
  text-align: center;
}

.instructions-description {
  padding: 0 16px;
  color: #666;
  text-align: center;
}

.exercise {
  box-sizing: border-box;
  min-height: 100vh;
  height: auto;
  background-color: #f4f4f4;
  padding: 0 0 200px;
  margin-top: 10px;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}

.answer-div {
  margin: 0 0 30px;
}

.answer-form {
  padding: 0;
  transition: all 0.25s ease-in-out;
  margin: 0;
}

.prompt {
  padding: 24px 24px 12px;
  font-size: 18px;
  font-weight: 700;
  color: #e85454;
}

.number {
  float: left;
  padding-right: 5px;
}

.answer-section {
  background-color: #fff;
  border-radius: 16px;
  padding: 16px 24px 30px;
}

.answer {
  resize: none;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: none;
  overflow: hidden;
  font-size: 16px;
  font-family: inherit;
  line-height: 1.8;
  min-height: 38px;
  height: 38px;
  color: #666;
  caret-color: #e85454;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

textarea {
  color: #666;
}

.next-button {
  cursor: pointer;
  font-size: 12px;
  border: none;
  background-color: transparent;
  float: right;
  outline: none;
  border-radius: 2px;
  margin-top: 0px;
  padding: 8px 8px;
  color: #aaa;
}

.last-button {
  background-color: #e85454;
  padding: 10px 45px;
  color: #fff;
  width: 100%;
  font-size: 0.9em;
  margin-top: 16px;
  border-radius: 32px;
  border: 2px solid #e85454;
  height: 56px;
  line-height: 32px;
  font-weight: 700;
  position: relative;
  top: 0;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  transition: top 0s ease-in-out;
}

.last-button:active {
  top: 2px;
}

#past {
  background-color: #fff;
  min-height: 50vh;
  padding: 40px 0;
}

.exercise-content {
  padding: 0;
  margin: 0;
  min-height: 50vh;
  width: 100%;
  position: relative;
}

.exercise-title {
  font-size: 36px;
  color: #e85454;
  width: 100%;
  padding: 0 0 15px;
  font-weight: 700;
  text-align: center;
}

.past-exercises {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 70px;
}

.example2-enter {
  opacity: 0.01;
}

.example2-enter.example2-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.example2-leave {
  opacity: 1;
  transform: translateX(0);
}

.example2-leave.example2-leave-active {
  opacity: 0.01;
  transform: translateX(30px);
  transition: all 500ms ease-in-out;
}

.past-exercise {
  cursor: pointer;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  margin: 12px 0;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 32px;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  transition: all 0.1s ease-in-out;
}

.past-exercise:hover {
  background-color: #eef0f0;
}

.exercise-date {
  flex: 7;
  padding: 16px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #666;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.1s ease-in-out;
}

.exercise-download {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #333;
  font-size: 18px;
  outline: none;
  opacity: 0.54;
  padding: 18px 0 14px;
  margin-left: 16px;
  text-align: right;
  transition: all 0.1s ease-in-out;
}

.exercise-download:hover {
  opacity: 1;
}

.exercise-delete {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #e85454;
  font-size: 14px;
  outline: none;
  opacity: 0.54;
  padding: 16px 0;
  margin-left: 16px;
  text-align: right;
  transition: all 0.1s ease-in-out;
}

.exercise-delete:hover {
  opacity: 1;
}

.take-again {
  position: absolute;
  bottom: 2px;
  background-color: #eef0f0;
  color: #e85454;
  font-size: 0.9em;
  padding: 10px 0;
  font-weight: 700;
  margin: 10px auto;
  display: block;
  border-radius: 32px;
  border: 2px solid #fff;
  height: 56px;
  line-height: 32px;
  width: 100%;
  outline: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out,
    border-color 0.1s ease-in-out, top 0s ease-in-out;
}

.hide {
  display: none !important;
}

.take-again:hover {
  color: #fff;
  background-color: #e85454;
  border-color: #e85454;
}

.take-again:active {
  bottom: 0;
}

.rate-button {
  width: auto;
  background-color: #e85454;
  color: #fff;
  padding: 16px 45px;
  border-radius: 40px;
  border: 2px solid #e85454;
  height: 32px;
  line-height: 32px;
  font-weight: 700;
  font-size: 0.9em;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
}

.rate {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #fff;
}

.app-store {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f4f4f4;
}

.share {
  padding-top: 40px;
  padding-bottom: 70px;
  background-color: #f4f4f4;
}

.share-title {
  padding-bottom: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #666;
}

.share-title-word {
  display: inline-block;
}

.share-word {
  font-size: 42px;
  padding-top: 25px;
  color: #e85454;
  font-weight: 700;
}

.social-buttons {
  height: 60px;
  display: flex;
  justify-content: center;
}

.social-button {
  min-height: 80px;
  height: 80px;
  min-width: 80px;
  width: 80px;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  background-color: #fff;
  font-size: 28px;
  color: #e85454;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  transition: all 0.1s ease-in-out;
}

.social-button:hover {
  background-color: #e85454;
  color: #fff;
  border-color: #e85454;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  text-align: center;
  color: #666;
}

.footer-info {
  padding: 6px;
  font-size: 1em;
}

.heart {
  color: #e85454;
  display: inline-block;
  animation-name: beat;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  font-size: 1em;
  padding: 0 2px;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.3);
  }
  35% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: #e85454;
}

.link:hover {
  color: #666;
}

@media (max-width: 800px) {
  .info {
    margin-left: 40px;
    margin-right: 40px;
  }

  .title {
    font-size: 5em;
  }
}

@media (max-width: 660px) {
  .title {
    font-size: 4em;
  }

  .description {
    font-size: 1.7em;
  }

  .start {
    display: none;
  }

  .enter-help {
    display: none;
  }

  .start-button {
    padding: 7px 35px;
  }
}

@media (max-width: 420px) {
  .info {
    margin-left: 30px;
    margin-right: 30px;
  }

  .small-info {
    padding: 20px 30px;
  }

  .title {
    font-size: 3.2em;
    margin: 0 0 25px;
  }

  .description {
    font-size: 1.4em;
    margin-bottom: 40px;
  }

  .prompt,
  .answer-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .prompt {
    padding-top: 15px;
  }

  .past-exercise {
    padding: 0 20px;
  }

  .exercise {
    padding-bottom: 60px;
  }

  .share {
    padding-bottom: 30px;
  }

  .social-buttons {
    flex-flow: row wrap;
    height: auto;
  }

  .social-button {
    margin: 0 20px 30px;
  }

  .social-button > svg {
    left: -4px;
  }
}

@media (max-width: 380px) {
  .info {
    margin-left: 20px;
    margin-right: 20px;
  }

  .small-info {
    padding: 20px 20px;
  }

  .title {
    font-size: 3em;
    margin: 0 0 25px;
  }

  .instruction-info {
    padding: 15px 30px;
  }

  .why-description {
    padding: 0;
  }

  .instructions-description {
    padding: 0;
  }

  .last-button {
    padding: 8px 45px;
    height: 52px;
  }

  .take-again {
    padding: 8px 45px;
    height: 52px;
  }

  .exercise-date,
  .exercise-delete,
  .exercise-download {
    padding: 12px 0;
  }

  .why {
    padding: 12px 0 0;
  }

  .instructions {
    padding: 0 0 12px;
  }
}

@media (max-width: 325px) {
  .title {
    font-size: 2.5em;
  }

  .description {
    font-size: 1.2em;
  }

  .start-button {
    padding: 6px 40px;
  }

  .why-title {
    font-size: 16px;
  }

  .instructions-title {
    font-size: 16px;
  }

  .prompt {
    font-size: 16px;
  }

  .info {
    margin-left: 20px;
    margin-right: 20px;
  }

  .small-info {
    padding: 20px 20px;
  }

  .exercise-title {
    font-size: 32px;
  }

  .share-title {
    font-size: 16px;
  }

  .share-word {
    font-size: 32px;
  }

  .social-button {
    min-height: 70px;
    height: 70px;
    min-width: 70px;
    width: 70px;
  }

  .last-button {
    padding: 6px 45px;
    height: 48px;
  }

  .take-again {
    padding: 6px 0;
    height: 48px;
  }

  .exercise-date,
  .exercise-delete,
  .exercise-download {
    padding: 12px 0;
  }

  .instruction-info {
    padding: 15px 30px;
  }

  .why-description {
    padding: 0;
  }

  .instructions-description {
    padding: 0;
  }
}
